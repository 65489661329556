import React from 'react';
import styled from 'styled-components';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';
import {
  CONTAINER_LAPTOP_WIDTH,
  CONTAINER_WIDTH, LAPTOP, MOBILE, TABLET, media,
} from '../../constants/screens';
import { TURQUOISE, WHITE, ERROR_RED } from '../../constants/colors';
import Arrow from '../../svg/more.svg';
import RoundedButton from '../buttons/roundedButton';

const JobOpenings = ({ image, jobs, hiringProcessPdf }) => (
  <Container>
    <Content>
      <TextContainer>
        <Title>
          /Current job openings
        </Title>
        {jobs.map(({ node: { id, title, shortlink } }, i) => (
          <Row key={id} isLast={i === jobs.length - 1}>
            <ButtonTitle>
              {title}
            </ButtonTitle>
            <RoundedButton
              text="find out here"
              color={TURQUOISE}
              hoverTextColor={WHITE}
              tag="a"
              href={shortlink}
              target="_blank"
            />
          </Row>
        ))}
        <DesktopContainer>
          <Text>
            {
              'Ready to apply for our job openings?\n Here’s what to expect'
            }
          </Text>
          {
            hiringProcessPdf
              ? (
                <Link href={hiringProcessPdf} target="_blank">
                  <LinkText>
                    see the process
                  </LinkText>
                  <Arrow />
                </Link>
              )
              : (
                <LinkText error>
                  Upload &quot;HiringProcessPdf&quot; file!
                </LinkText>
              )
          }
        </DesktopContainer>
      </TextContainer>
      <ImageContainer>
        <Image sizes={image.childImageSharp.sizes} />
      </ImageContainer>
      <MobileContainer>
        <Text>
          {
            'Ready to apply for our job openings?\n Here’s what to expect'
          }
        </Text>
        {
          hiringProcessPdf
            ? (
              <Link href={hiringProcessPdf} target="_blank">
                <LinkText>
                  see the process
                </LinkText>
                <Arrow />
              </Link>
            )
            : (
              <LinkText error>
                Upload &quot;HiringProcessPdf&quot; file!
              </LinkText>
            )
        }
      </MobileContainer>
    </Content>
  </Container>
);

JobOpenings.propTypes = {
  image: PropTypes.shape().isRequired,
  jobs: PropTypes.instanceOf(Array),
  hiringProcessPdf: PropTypes.string.isRequired,
};

JobOpenings.defaultProps = {
  jobs: [],
};

const MobileContainer = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media screen and (max-width: ${TABLET}) {
    display: flex;
  }
`;

const DesktopContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media screen and (max-width: ${TABLET}) {
    display: none;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 130px 5% 170px;
  background-image: linear-gradient(126deg, #2a159a, #2a159a 46%, #2b55c5);
  
  @media screen and (max-width: ${LAPTOP}) {
    padding: 100px 5% 130px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    padding: 75px 5% 80px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  max-width:  ${CONTAINER_WIDTH};
  width: 100%;
  align-items: center;
  justify-content: space-between;
  
  ${media.laptop`
    max-width: ${CONTAINER_LAPTOP_WIDTH};
  `};
  
  @media screen and (max-width: ${TABLET}) {
    flex-direction: column;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 50;
  
  @media screen and (max-width: ${TABLET}) {
    align-items: center;
  }
`;

const Title = styled.h4`
  font-size: 40px;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
  color: ${TURQUOISE};
  margin-bottom: 70px;
  
  @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
    font-size: 26px;
    margin-bottom: 50px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    font-size: 24px;
  }
`;

const ImageContainer = styled.div`
  flex: 50;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 0 0 70px;
  
  @media screen and (max-width: ${TABLET}) {
    max-width: 600px;
    margin: 70px 0 0 0;
    padding: 0;
  }
  
  > div {
    width: 100%;
  }
`;

const ButtonTitle = styled.h4`
  font-size: 30px;
  line-height: normal;
  letter-spacing: normal;
  color: ${WHITE};
  text-transform: uppercase;
  margin-right: 50px;
  white-space: pre-line;
  flex-grow: 1;
  
  @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
    font-size: 22px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    font-size: 20px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    margin-right: 0px;
    text-align: center;
    margin-bottom: 15px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: ${({ isLast }) => (isLast ? '0px' : '35px')};
  align-items: center;
  
  @media screen and (max-width: ${LAPTOP}) {
    margin-bottom: ${({ isLast }) => (isLast ? '0px' : '25px')};
  }
  
  @media screen and (max-width: ${TABLET}) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  & > a, & > button {
    flex-shrink: 0;
  }
`;

// eslint-disable-next-line
const Text = styled.p`
  font-family: AvenirNextLTPro;
  font-size: 21px;
  line-height: normal;
  letter-spacing: normal;
  color: ${WHITE};
  white-space: pre-line;
  margin-top: 70px;
  
  @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
    font-size: 19px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    font-size: 17px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    text-align: center;
  }
`;

// eslint-disable-next-line
const Link = styled.a`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  text-decoration: none;
  
  > svg {
    width: 20px;
    height: 20px;
    margin-top: -1px;
    
    @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
      margin-top: -3px;
    }
    
    circle {
      fill: ${TURQUOISE};
    }
    path {
      fill: ${WHITE};
    }
  }
`;

// eslint-disable-next-line
const LinkText = styled.p`
  font-family: AvenirNextLTPro;
  font-size: 19px;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => (props.error ? ERROR_RED : TURQUOISE)};
  text-transform: ${props => (props.error ? 'initial' : 'uppercase')};
  margin: 0 25px 0 0 ;
  
  @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
    font-size: 17px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    font-size: 15px;
  }
`;

export default JobOpenings;
