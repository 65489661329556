import React from 'react';
import styled from 'styled-components';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';
import {
  CONTAINER_LAPTOP_WIDTH,
  CONTAINER_WIDTH, LAPTOP, MOBILE, TABLET, media,
} from '../../constants/screens';
import { TURQUOISE, WHITE } from '../../constants/colors';

const ValuesItem = ({ id, title }) => (
  <Value key={id}>
    <ValueText>
      {title}
    </ValueText>
  </Value>
);

ValuesItem.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

const Values = ({
  image: { file: { localFile: { childImageSharp: { sizes } } } },
  title,
  subTitle,
  content,
}) => (
  <Container>
    <Content>
      <Title>
        {title}
      </Title>
      <Text>
        {subTitle}
      </Text>
      <ContentRow>
        <ValuesContainer>
          {
            content.map(ValuesItem)
          }
        </ValuesContainer>
        <ImageContainer>
          <Image sizes={sizes} alt="slashdata values" />
        </ImageContainer>
      </ContentRow>
    </Content>
  </Container>
);

Values.propTypes = {
  image: PropTypes.shape().isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 155px 5% 125px;
  background-image: linear-gradient(126deg, #2a159a, #2a159a 46%, #2b55c5);
  
  @media screen and (max-width: ${TABLET}) {
    padding: 75px 5%;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width:  ${CONTAINER_WIDTH};
  width: 100%;
  align-items: center;
  justify-content: center;
  
  ${media.laptop`
    max-width: ${CONTAINER_LAPTOP_WIDTH};
  `};
`;

const Title = styled.h4`
  font-size: 40px;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
  color: ${TURQUOISE};
  margin-bottom: 25px;
  
  @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
    font-size: 26px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    font-size: 24px;
  }
`;

const Text = styled.p`
  font-family: AvenirNextLTPro;
  font-size: 21px;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${WHITE};
  max-width: 475px;
  margin-bottom: 50px;
  
  @media screen and (max-width: ${TABLET}) {
    margin-bottom: 0px;
  }
  
  @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
    font-size: 19px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    font-size: 17px;
  }
`;

const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  align-self: stretch;
  justify-content: space-between;
  
  @media screen and (max-width: ${TABLET}) {
    flex-direction: column;
  }
`;

const ValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.4;
  align-items: center;
  
  > div:not(:last-child) {
    margin-bottom: 10px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    margin: 80px 0;
  }
`;

const Value = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 450px;
  width: 100%;
  background: rgba(0, 0, 0, 0.25);
  min-height: 105px;
  
  ${media.only.laptop`
    min-height: 80px;
  `}
`;

const ValueText = styled.h4`
  font-size: 30px;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
  color: ${WHITE};
  
  @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
    font-size: 22px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    font-size: 20px;
  }
`;

const ImageContainer = styled.div`
  flex: 0.45;
  width: 100%;
  display: flex;
  align-items: center;
  
  @media screen and (max-width: ${TABLET}) {
    max-width: 600px;
    align-self: center;
  }
  
  > div {
    width: 100%;
  }
`;

export default Values;
