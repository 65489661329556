import React from 'react';
import PropTypes from 'prop-types';
import { compose, pathOr } from 'ramda';
import { graphql } from 'gatsby';
import Introduction from '../components/careersPage/introduction';
import Values from '../components/careersPage/values';
import WeOffer from '../components/careersPage/weOffer';
import { DARK_SCHEME } from '../constants/colors';
import withStickyHeader from '../enhancers/withStickyHeader';
import withCustomLayout from '../enhancers/withCustomLayout';
import withScroll from '../enhancers/withScroll';
import JobOpenings from '../components/careersPage/jobOpenings';

const Careers = (props) => {
  const {
    data: {
      jobOpeningsImage,
      hiringProcess,
      jobs: { edges: jobs },
      page: { introduction, sections },
    },
    scrollTo,
  } = props;

  const hiringProcessPdf = pathOr(null, ['file', 'url'], hiringProcess);

  const valuesSection = sections.filter(({ meta }) => meta.includes('values'));
  const offerSection = sections.filter(({ meta }) => meta.includes('offer'));

  return (
    <div>
      <Introduction {...introduction} scrollTo={scrollTo} />
      <Values {...valuesSection[0]} />
      <WeOffer {...offerSection[0]} />
      <JobOpenings image={jobOpeningsImage} hiringProcessPdf={hiringProcessPdf} jobs={jobs} />
    </div>
  );
};

export default compose(
  withScroll({ sections: { values: [1], jobs: [3] } }),
  withStickyHeader(),
  withCustomLayout({ scheme: DARK_SCHEME }),
)(Careers);

Careers.propTypes = {
  data: PropTypes.shape().isRequired,
  scrollTo: PropTypes.func.isRequired,
};

// eslint-disable-next-line
export const query = graphql`
  query CareersPageQuery {
    jobOpeningsImage: file(relativePath: { regex: "/careers-3.png/" }) {
      childImageSharp {
        sizes(maxWidth: 785) {
          ...GatsbyImageSharpSizes_noBase64
        }
      }
    }
    hiringProcess: fileUpload(name: { eq: "HiringProcessPdf" }) {
      file{
        url
      }
    }
    jobs: allJob {
      edges {
        node {
          id
          title
          shortlink
        }
      }
    }
    testimonials: allTestimonial {
      edges {
        node {
          id
          name
          role
          content {
            html
          }
        }
      }
    }
    page(url: { eq: "/careers" }) {
      url
      introduction {
        content {
          title
          description {
            html
          }
          button {
            url
            text
          }
        }
        image {
          file {
            localFile {
              childImageSharp {
                sizes(maxWidth: 820) {
                  ...GatsbyImageSharpSizes_noBase64
                }
              }
            }
          }
        }
      }
      sections {
        id: _id
        name
        meta
        title
        subTitle
        image {
          alt
          file {
            localFile {
              childImageSharp {
                sizes(maxWidth: 640) {
                  ...GatsbyImageSharpSizes_noBase64
                }
              }
            }
          }
        }
        content {
          id: _id
          icon {
            file {
              localFile {
                publicURL
              }
            }
          }
          title
          lead
          button {
            text
            url
          }
          description {
            html
          }
        }
      }
    }
  }
`;
