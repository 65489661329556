import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  CONTAINER_LAPTOP_WIDTH,
  CONTAINER_WIDTH, LAPTOP, MOBILE, TABLET, media,
} from '../../constants/screens';
import { BLUE, WHITE } from '../../constants/colors';

const OfferItem = ({
  id,
  title,
  description: { html },
  icon: { file: { localFile: { publicURL } } },
}) => (
  <OfferItemWrapper key={id}>
    <OfferItemIcon>
      <img src={publicURL} alt="" />
    </OfferItemIcon>
    <OfferItemTitle>
      {title}
    </OfferItemTitle>
    <OfferItemText dangerouslySetInnerHTML={{ __html: html }} />
  </OfferItemWrapper>
);

OfferItem.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  description: PropTypes.shape().isRequired,
  icon: PropTypes.shape().isRequired,
};

const WeOffer = ({ title, content }) => (
  <Container>
    <Content>
      <Title>
        {title}
      </Title>
      <OfferContent>
        {content.map(OfferItem)}
      </OfferContent>
    </Content>
  </Container>
);

WeOffer.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${WHITE};
  padding: 175px 5% 55px;
  
  @media screen and (max-width: ${LAPTOP}) {
    padding: 130px 5% 40px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    padding: 75px 5%;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width:  ${CONTAINER_WIDTH};
  width: 100%;
  align-items: center;
  justify-content: center;
  
  ${media.laptop`
    max-width: ${CONTAINER_LAPTOP_WIDTH};
  `};
`;

const Title = styled.h4`
  font-size: 40px;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${BLUE};
  text-transform: uppercase;
  margin-bottom: 100px;
  
  @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
    font-size: 26px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    font-size: 24px;
  }
`;

const OfferContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  
  @media screen and (max-width: ${TABLET}) {
    flex-direction: column;
    align-items: center;
    
    > div:not(:last-child) {
      margin-bottom: 120px;
    }
  }
`;

const OfferItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 36px 4px rgba(220, 227, 238, 0.61);
  flex-basis: 30%;
  border-radius: 10px;
  padding: 120px 40px 80px;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 120px;
  
  @media screen and (max-width: ${LAPTOP}) {
    margin-bottom: 90px;
    padding: 90px 30px 65px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    max-width: 500px;
    margin-bottom: 0;
  }
`;

const OfferItemTitle = styled.h4`
  font-size: 33px;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${BLUE};
  white-space: pre-line;
  text-transform: uppercase;
  height: 96px;
  display: flex;
  align-items: flex-end;
  margin-bottom: 25px;
  
  @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
    font-size: 24px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    font-size: 20px;
  }
`;

const OfferItemText = styled.p`
  font-family: AvenirNextLTPro;
  font-size: 21px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  
  @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
    font-size: 18px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    font-size: 17px;
  }
`;

const OfferItemIcon = styled.div`
  position: absolute;
  top: -50px;
  
  > img {
    width: 100px;
    height: 100px;
  }
  
  ${media.only.laptop`
    top: -35px;
    
    > img {
      width: 70px;
      height: 70px;
    }
  `}
`;

export default WeOffer;
