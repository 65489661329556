import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import AboutUsItem from '../common/aboutUsItem';
import Info from '../common/info';
import RoundedButton from '../buttons/roundedButton';
import {
  CONTAINER_LAPTOP_WIDTH, CONTAINER_WIDTH, TABLET, media, LAPTOP,
} from '../../constants/screens';
import { BLACK, BLUE, WHITE } from '../../constants/colors';

const Introduction = ({
  content: {
    0: {
      button: { url, text },
      description: { html }, title,
    },
  },
  image: {
    file: { localFile: { childImageSharp: { sizes } } },
  },
  scrollTo,
}) => (
  <Container>
    <Content>
      <AboutUsItem
        image={sizes}
        imgAltAttr="SlashData - Join our team"
        maxImageWidth="770px"
        reversed
      >
        <Info
          subTitle={title}
          text={html}
          subTitleColor={BLUE}
          textColor={BLACK}
          subTitleFontSize="40px"
          withButton
          buttonText={text}
          link={url}
          buttonColor={BLUE}
          reversed
          pageName="careers"
          onButtonClick={() => {
            setTimeout(() => scrollTo('jobs'), 300);
          }}
        />
      </AboutUsItem>
    </Content>
    <ButtonWrapper>
      <RoundedButton
        text={text}
        link={url}
        color={BLUE}
        hoverTextColor={WHITE}
      />
    </ButtonWrapper>
  </Container>
);

Introduction.propTypes = {
  image: PropTypes.shape().isRequired,
  content: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  scrollTo: PropTypes.func.isRequired,
};

const ButtonWrapper = styled.div`
  display: none;
  margin-top: 70px;
  
  @media screen and (max-width: ${TABLET}) {
    display: block;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${WHITE};
  padding: 300px 5% 150px 5%;
  
  @media screen and (max-width: ${LAPTOP}) {
    padding: 220px 5% 120px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    padding: 200px 5% 75px 5%;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  max-width:  ${CONTAINER_WIDTH};
  width: 100%;
  align-items: center;
  justify-content: center;
  
  ${media.laptop`
    max-width: ${CONTAINER_LAPTOP_WIDTH};
  `};
  
  @media screen and (max-width: ${TABLET}) {
    flex-direction: column;
    align-items: center;
    
    > div > div > div > div {
      margin-top: 0;
      
      > button {
        display: none;
      }
    }
  }
  
  > div {
    width: 100%;
    
    @media screen and (min-width: ${TABLET}) {
      > div:first-child {
        > * {
          text-align: left;
          align-items: flex-start;
        }
        
        > div > div {
          justify-content: flex-start;
        }
      }
    }
  }
`;

export default Introduction;
